import React from "react"

// returns a traffic chart pattern for the type of listing
const TrafficChart = ({status}) => {

  const charts = {
    'Free': [1,2,2,2,2,2,2,2,2,2],
    'Free w/o Reviews': [1,2,2,2,2,2,2,2,2,2],
    'Free w/ Reviews': [2,2,2,3,3,3,4,4,4,4],
    'Featured': [7,7,7,4,4,4,4,4,4,4],
    'Featured 1x': [7,7,7,4,4,4,4,4,4,4],
    'Featured 2x': [10,10,10,6,5,4,4,4,4,4],
    'Gold Membership': [1,3,5,7,7,7,7,7,7,7],
    'Gold + Featured': [7,7,8,9,9,9,10,10,10,10],
  }
  const chart = charts[status];
  if (!chart) return ''

  return (
    <div className="flex items-end">
      {chart.map((c, index) => (
        <div key={`${c}-${index}`} className="w-2 bg-rose-400 rounded-sm" style={{height: `${c * 2}px`}}></div>
      ))}
    </div>
  ); 
}

export default TrafficChart;

