import React from "react"
import { Link, graphql } from "gatsby"
import ReactTooltip from 'react-tooltip';

import Layout from "../../components/promotions/layout"
import SEO from "../../components/common/seo"

import PaymentLink from "../../components/promotions/paymentLink"
import TrafficChart from "../../components/promotions/trafficChart"

// import { toQueryString } from "../../utils/urls"
import { communityPromotionLink, communityGoldSubscriptionLink, communityPromotionSubscriptionLink } from "../../components/promotions/constants"


const CommunityPromotionPage = ({data, path}) => {

  const estimates = data.allEstimates.nodes;
  const statusesToInclude = ['Free', 'Featured 1x', 'Featured 2x', 'Gold Membership', 'Gold + Featured'];

  return (
    <Layout pageTitle="Promote your community" enableShare={true} includeBlurb={true}>

      <SEO title="Community Promotion | Grow your community" description="Reach more visitors on the Hive Index by sponsoring your listing." 
        path={'/partners/community/'} generateImage={true}
      />

      <div className="md:flex md:space-x-8 space-y-8 md:space-y-0 mt-4">
        <div className="flex-1 rounded-3xl ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Featured Listing (1 month)</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Get featured on top of 1 topic page to boost community memberships for a month.
            </p>
            <div className="mt-4 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-rose-600">Sponsorship includes</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                Featured on top of your topic page
              </li>
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                Displayed on our homepage
              </li>
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <div>Recommended from similar communities</div>
              </li>
              <li className="flex gap-x-3 items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <ReactTooltip id={`cpc-guarantee`} place="top" effect="solid" backgroundColor="black" textColor="white"
                  className="max-w-xl"
                />
                <div className="flex items-center">
                  <div>$1 CPC guarantee</div>

                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    className="w-5 h-5 ml-1 opacity-50"
                    data-for="cpc-guarantee" data-tip="If you get <99 unique referrals from your promotion, we'll keep it running until you reach 99. The upside could be much higher than 99 based on your topic page, but this guarantee removes the risk of a weak promo."
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>
                </div>
              </li>

            </ul>
          </div>
          <div className="-mt-2 p-2">
            <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
              <div className="mx-auto max-w-xs px-1">
                <p className="text-base font-semibold text-gray-600">One-time fee</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$99</span>
                </p>
                <PaymentLink
                  url={communityPromotionLink}
                  params={{'utm_content': 'community_promotion'}}
                  title="Promote your community"
                  additionalClassName="mt-10"
                />
                <p className="mt-6 text-sm leading-5 text-gray-600">
                 Comes with $1 CPC guarantee (99+ clicks)
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className="flex-1 rounded-3xl ring-1 ring-gray-200">
          <div className="p-6 sm:p-8">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Gold Membership</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              List on up to 6 topic pages related to your community, and have more prominent listings. Gold listings get 10x traffic on average.
            </p>
            <div className="mt-4 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-rose-600">Membership includes</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                Be listed on up to 6 relevant topic pages (instead of 2)
              </li>
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
               ~10x organic traffic to your community
              </li>
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <div>Show social proof (show reviews in list, choose top 3)</div>
              </li>
              <li className="flex gap-x-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="h-6 w-5 flex-none text-rose-600"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
                <div className="flex items-center">
                  <div>More prominent display in "similar communities"</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="-mt-2 p-2">
            <div className="rounded-2xl bg-yellow-50 py-12 text-center ring-1 ring-inset ring-yellow-100 lg:flex lg:flex-col lg:justify-center lg:py-12">
              <div className="mx-auto max-w-xs px-1">
                <p className="text-base font-semibold text-gray-600">Yearly Subscription</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">$390<span className="opacity-50 text-3xl"> /yr</span></span>
                </p>
                <PaymentLink
                  url={communityGoldSubscriptionLink}
                  params={{'utm_content': 'gold_membership'}}
                  title="Level up your listing"
                  additionalClassName="mt-10"
                />
                <p className="mt-6 text-sm leading-5 text-gray-600">
                 Gold listings get ~10x traffic of free listings
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-8">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900 flex items-center">
            <div>Traffic Stats</div>
            <span className="ml-2 opacity-50 text-sm">Averages from past month, refreshed weekly</span>
          </h2>
        </div>
      </div>
      <div className="mt-4 text-gray-500">
        <ReactTooltip id={`table-tooltip`} place="top" effect="solid" backgroundColor="black" textColor="white"
          className="max-w-xl"
        />
        <table>
          <thead>
            <tr>
              <th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Listing type for these monthly stats"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Listing Type</span>
              </th>
              <th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Average clicks out to a community for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Clicks/mo</span>
              </th>
              
              <th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="Monthly cost for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Cost/mo</span>
              </th>
              <th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900"
                data-for="table-tooltip" data-tip="Average cost per click for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">CPC</span>
              </th>
              
              {/*<th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="How many monthly pageviews of a community listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Views/mo</span>
              </th>*/}
              {/*<th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="How many unique people view a community on the Hive Index"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Visitors/mo</span>
              </th>*/}
              
              <th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="Typical traffic pattern for this type of listing"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">Pattern</span>
              </th>
              {/*<th scope="col" className="pr-4 sm:pr-8 text-left font-semibold text-gray-900 hidden sm:table-cell"
                data-for="table-tooltip" data-tip="What percent of visitors turn to clicks"
              >
                <span className="border-b-2 border-yellow-400 border-dotted">CTR</span>
              </th>*/}
            </tr>
          </thead>
          <tbody>
            {estimates.filter(e => statusesToInclude.includes(e.status)).map(e => (
              <tr key={e.status}>
                <td className="py-2 pr-4 sm:pr-12">
                  <div className="font-semibold">{e.status}</div>
                  <div className="text-sm opacity-75">{e.description}</div>
                </td>
                <td className="py-2 pr-4 sm:pr-12">{e.leads__mo}</td>
                
                <td className="py-2 hidden sm:table-cell">${e.cost_Mo}</td>
                <td className="py-2 pr-4 sm:pr-12">${e.cpc.toFixed(2)}</td>
                
                {/*<td className="py-2 hidden sm:table-cell">{e.views__mo}</td>*/}
                {/*<td className="py-2 hidden sm:table-cell">{e.visitors__mo}</td>*/}
                
                <td className="py-2 hidden sm:table-cell">
                  <TrafficChart status={e.status} />
                </td>
                {/*<td className="py-2 hidden sm:table-cell">{e.ctr}</td>*/}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">Testimonials</h2>
        </div>
      </div>

      <div className="mt-4 text-gray-500 md:flex md:space-x-4 ">
        <p className="flex-1 mb-2">
          <b>"It turned out to be a huge success story and TheHiveIndex became one of our key sources of new members. Can't recommend enough and thank you for helping us reach new heights."</b>
          <br/>- Ophir from <Link to="/communities/furlough-community/" className="underline cursor-pointer">Furlough</Link>
        </p>
        <p className="flex-1 mb-2">
          <b>"Hive Index is the best source of referral traffic to grow your community. Visitors coming from our listing are highly converting ones! It's one of a few marketing channels with a positive ROI."</b>
          <br/>- Ivan from <Link to="/communities/lunadio/" className="underline cursor-pointer">Lunadio</Link>
        </p>
        {/*<p className="mb-2">
          <b>"The value makes sense! I'm down for another month, or can you do 3 or 6?"</b>
          <br/>- Anthony from <Link to="/communities/indie-worldwide/" className="underline cursor-pointer">Indie Worldwide</Link>
        </p>*/}
        <p className="flex-1 mb-2">
          <b>"If you are running a community, The HiveIndex Gold Membership is a deal you must grab. We have been sponsoring for our MicroSaaSHQ community. Drives traffic consistently. I would highly recommend."</b>
          <br/>- Upen from <Link to="/communities/micro-saas-hq/" className="underline cursor-pointer">MicroSaasHQ</Link>
        </p>
        <p className="flex-1 mb-2">
          <b>"If you want a source of good traffic for your community this is well worth a look. For quite a while Hive Index has been our #1 source of traffic."</b>
          <br/>- Simon from <Link to="/communities/hacker-cabin/" className="underline cursor-pointer">Hacker Cabin</Link>
        </p>
      </div>


      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex items-center justify-between">
          <h2 className="pr-3 bg-white text-xl font-medium text-gray-900">FAQ</h2>
        </div>
      </div>

      <div className="mt-2 text-gray-500">
        <p className="mb-2">
          <b>How much does it cost?</b><br/>
          $99 to be featured for a month
        </p>
        <p className="mb-2">
          <b>Can I promote my listing more than once?</b><br/>
          Yes
        </p>
        <p className="mb-2">
          <b>Can I promote my listing on more than one topic page?</b><br/>
          Yes, just increase the quantity when using the payment link
        </p>
        <p className="mb-2">
          <b>Can I run a featured promotion and purchase Gold membership?</b><br/>
          Yes, this is the highest-traffic package you can get on the Hive Index.
        </p>
         <p className="mb-2">
          <b>Is there a discount for buying multiple months of a promotion?</b><br/>
          Buy 4 months (by increasing the quantity to 4 on <a href={communityPromotionLink} className="font-semibold underline text-rose-600">this payment link</a>), get 1 free
        </p>
        <p className="mb-2">
          <b>Can I buy a promotion subscription, so I don't have to remember to pay each month?</b><br/>
          Yes, you can buy a subscription via <a href={communityPromotionSubscriptionLink} className="font-semibold underline text-rose-600">this payment link</a>.
        </p>
        <p className="mb-2">
          <b>Can I edit any other information on my listing?</b><br/>
          <a href="mailto:contact@thehiveindex.com" className="font-semibold underline text-rose-600">Send an email</a> and let's discuss!
        </p>
        {/*<p className="mb-2">
          <b>I'm not ready to pay, can I list my community for free?</b><br/>
          Yes, it's free to <Link to="/submit/" className="font-semibold underline">submit your community</Link> on the Hive Index.<br/>
          Your community will show up on relevant topic/feature pages in the default order & display style.
        </p>*/}
      </div>

    </Layout>
  )
}

export const query = graphql`
query {
  allEstimates: allGoogleSpreadsheetSourceEstimates(sort: {fields: order, order: ASC}) {
    totalCount
    nodes {
      status
      cost_Mo
      views__mo
      visitors__mo
      leads__mo
      description
      cpc
      ctr
    }
  }
}
`

export default CommunityPromotionPage
