import React from "react"
// import { Link } from "gatsby"

// import Layout from "../../components/common/layout"
// import SEO from "../../components/common/seo"

import { toQueryString } from "../../utils/urls"
import { trackEvent } from "../../utils/tracking"



const PaymentLink = ({url, params, title, className, additionalClassName}) => {
  // url is stripe link, with additional query params
  const paymentLink = `${url}${toQueryString(params)}`;

  const trackClick = () => {
    // trackEvent
    trackEvent("PaymentLinkClick", 
      Object.assign({
        'url': url,
        'title': title,
      }, params) // the url and title of the link, along with any UTM params added
    )
  }

  return (
    <a
      href={paymentLink} target="_blank" rel="noreferrer" onClick={trackClick}
      className={`${className || `block w-full rounded-md bg-rose-600 px-5 py-3 text-center cursor-pointer text-base font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600 ${additionalClassName}`}`}
      // style={{background: '#FE5F55', color: 'white', fontSize: '14px', padding: '10px 15px', borderRadius: '3px', border: 'none', cursor: 'pointer'}}
    >
      {title}
    </a>
  )
}


export default PaymentLink
