import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/common/layout"
import SEO from "../../components/common/seo"
import withLocation from "../../components/common/withlocation"

// import PromoteCommunity from "../../components/promotions/promoteCommunity"

// import Image from "../../components/image"


const tabs = [
  { name: 'Site Sponsorship', nameShort: 'Sponsorship', href: '/partners/sponsor/', price: '$1000' },
  { name: 'Community Promotion', nameShort: 'Community', href: '/partners/community/', price: '$99' },
  // { name: 'Platform Promotion', href: '/partners/platform/', price: '$250' },
  { name: 'Traffic Reports', nameShort: 'Traffic', href: '/partners/traffic/', right: true},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PartnersLayout = ({pageTitle, enableShare, children, path, location, includeBlurb}) => {

  return (
    <Layout pageTitle={pageTitle} enableShare={enableShare}>
      <div className="mb-6 -mt-4">
        <div className="block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.href}
                  className={classNames(
                    location.pathname.startsWith(tab.href)
                      ? 'border-rose-500 text-rose-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                    tab.right ? 'ml-auto' : 'mr-2 sm:mr-6'
                  )}
                  aria-current={tab.href === path ? 'page' : undefined}
                >
                  <span className="hidden sm:inline-block">{tab.name}</span>
                  <span className="inline-block sm:hidden">{tab.nameShort}</span>

                  {tab.price ? (
                    <span
                      className={classNames(
                        tab.href === path ? 'bg-rose-50 text-rose-600' : 'bg-gray-100 text-gray-900',
                        'ml-1 sm:ml-3 rounded-full py-0.5 px-2.5 text-xs font-medium inline-block'
                      )}
                    >
                      {tab.price}
                    </span>
                  ) : null}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/*{includeBlurb ? (
        <p className="text-base leading-7 text-gray-600">
          The Hive Index is a completely free resource for those seeking community. This is made possible by our wonderful sponsors.
          If you would like to spread the word about your community, platform, tool, or business (all while supporting free access to curated communities), then consider a sponsorship!
        </p>
      ) : ''}*/}

      <div>
        {children}
      </div>

    </Layout>
  )
}


export default withLocation(PartnersLayout)
