const isProd = process.env.NODE_ENV !== 'development';

const communityPromotionLink = isProd ? 'https://buy.stripe.com/7sIg0p1aZ0tbabe8ww' : 'https://buy.stripe.com/test_cN217F17J2JW79C9AA';
const communityPromotionSubscriptionLink = isProd ? 'https://buy.stripe.com/7sI29z7zna3LgzC9AC' : 'https://buy.stripe.com/test_8wM7w34jV0BO65y9AG';
const communityGoldSubscriptionLink = isProd ? 'https://buy.stripe.com/9AQ01r8Dr5NvbfidQZ' : 'https://buy.stripe.com/test_8wMdUr8AbfwIeC4aEJ';
const platformPromotionLink = isProd ? '' : '';
const sponsorPromotionLink = isProd ? 'https://buy.stripe.com/aEUaG53j77VDdnqdR2' : 'https://buy.stripe.com/test_aEUcQn9EfesE9hKaEF';


export {
    communityPromotionLink,
    communityPromotionSubscriptionLink,
    communityGoldSubscriptionLink,
    platformPromotionLink,
    sponsorPromotionLink,
}

